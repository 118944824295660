import { inject, Pipe, PipeTransform } from '@angular/core';

import { AuthSession } from './auth.service';
import { PermissionService, UserAction } from './permission.service';

@Pipe({ name: 'canUser' })
export class CanUserPipe implements PipeTransform {
  private permissions = inject(PermissionService);

  transform(session: AuthSession, action: UserAction) {
    return this.permissions.can(session.claims, action);
  }
}
