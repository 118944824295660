import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { Claims } from '@rpm/shared/schemas';

import { AuthService } from './auth.service';

export type UserAction =
  | 'viewShops'
  | 'viewAdmin'
  | 'viewOrgAnalysis'
  | 'createOrg'
  | 'createTestUsers'
  | 'editOrg'
  | 'editUsers'
  | 'editAdminUsers'
  | 'viewUserStats';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private auth = inject(AuthService);

  canUser(action: UserAction) {
    return this.can(this.auth.session().claims, action);
  }

  can(claims: Claims, action: UserAction) {
    switch (action) {
      case 'viewShops':
        return Object.values(claims.shops ?? {}).length > 0;
      case 'viewOrgAnalysis':
        return (
          claims.role === 'admin' ||
          claims.role === 'orgOwner' ||
          Object.values(claims.groups ?? {}).some(
            (group) => group.role === 'coach',
          )
        );
      case 'viewAdmin':
      case 'editOrg':
      case 'editUsers':
        return claims.role === 'admin' || claims.role === 'orgOwner';
      case 'createOrg':
      case 'createTestUsers':
      case 'editAdminUsers':
      case 'viewUserStats':
        return claims.role === 'admin';
    }
  }
}

/**
 * For protecting routes based on user permissions.
 */
export function canUser(action: UserAction): CanActivateFn {
  return async (route, state) => {
    const auth = inject(AuthService);
    const permission = inject(PermissionService);
    const router = inject(Router);

    const session = await auth.getSession();

    if (!session) {
      // redirect home with `returnTo` param,
      // so this guard can be re-run after sign in & redirect
      return router.createUrlTree([], {
        queryParams: { returnTo: state.url },
      });
    }

    return permission.canUser(action) || router.createUrlTree(['404']);
  };
}
